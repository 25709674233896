import React, { Component } from 'react';
import { Typography, ListItem, List, ListItemAvatar } from '@material-ui/core';
import logo from '../../static/assets/logo.png';
import myAccount from '../../static/assets/my-account.png';
import myClinic from '../../static/assets/my-clinic.png';
import callHistory from '../../static/assets/call-history.png';
import allPhyscians from '../../static/assets/all-physcians.png';
import searchClinic from '../../static/assets/search-clinic.png';
import requestList from '../../static/assets/request-list.png';
import myAccount1 from '../../static/assets/my-account1.png';
import myClinic1 from '../../static/assets/my-clinic1.png';
import callHistory1 from '../../static/assets/call-history1.png';
import allPhyscians1 from '../../static/assets/all-physcians1.png';
import searchClinic1 from '../../static/assets/search-clinic1.png';
import requestList1 from '../../static/assets/request-list1.png';
import sidebar from '../../static/assets/sidebar-bg.png';
import { NavLink } from 'react-router-dom';
import '../../static/styles/sidebar.css';
import StorageConfiguration from '../../services/storage-configuration';

export default class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isloggedIn: StorageConfiguration.sessionGetItem(StorageConfiguration.isloggedIn)
        };
    }

    componentDidMount() {
        var res = window.location.href.split("/");
        if (res[3] === 'all-physicians') {
            let activeElement = document.getElementById('my-clinic');
            if (activeElement) {
                activeElement.classList.add("activeLink");
            }
        }
        if (res[3] === 'change-password') {
            let activeElement = document.getElementById('my-account');
            if (activeElement) {
                activeElement.classList.add("activeLink");
            }
        }
    }

    render() {
        var role = Number(StorageConfiguration.sessionGetItem('role'));
        var currentLocation = window.location.href.split("/");
        var currentRoute = currentLocation[3];

        return (
            <div>
                <div className="sidenav" style={{ background: '#FFF', zIndex: '1 !important', backgroundImage: `url(${sidebar})`, height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className='sidebar-logo-view'>
                        <NavLink to="/my-clinic">
                            <img alt='no_image' src={logo} className="sidebar-logo"></img>
                        </NavLink>
                    </div>

                    <div >
                        <List  >
                            <div>
                                {role !== 2 ?
                                    <div>
                                        <NavLink id="my-clinic" to="/my-clinic" exact activeClassName="activeLink">
                                            <ListItem button>
                                                <div className="sidenav-card sidebar-content" >
                                                    <ListItemAvatar>
                                                        <div className="sidebar-icon-outer">
                                                            {currentRoute === 'my-clinic' || currentRoute === 'all-physicians' ?
                                                                <img className="sidebar-image" alt='no_image' src={myClinic}></img>
                                                                :
                                                                <img className="sidebar-image" alt='no_image' src={myClinic1}></img>
                                                            }
                                                        </div>
                                                    </ListItemAvatar>
                                                    <Typography className="sidenav-title sidebar-title" variant="subtitle1"> MY CLINIC </Typography>
                                                </div>
                                            </ListItem>
                                        </NavLink>
                                        <div className="sidebar-border">
                                            <div className="sidebar-border-line"> </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }

                                {role === 2 ?
                                    <div>
                                        < NavLink to="/all-physicians" exact activeClassName="activeLink">
                                            <ListItem button  >
                                                <div className="sidenav-card sidebar-content" >
                                                    <ListItemAvatar>
                                                        <div className="sidebar-icon-outer">
                                                            {currentRoute === 'all-physicians' ?
                                                                <img className="sidebar-image" alt='no_image' src={allPhyscians}></img>
                                                                :
                                                                <img className="sidebar-image" alt='no_image' src={allPhyscians1}></img>
                                                            }
                                                        </div>
                                                    </ListItemAvatar>
                                                    <Typography className="sidenav-title sidebar-title" variant="subtitle1"> PHYSICIANS </Typography>
                                                </div>
                                            </ListItem>
                                        </NavLink>
                                        <div className="sidebar-border">
                                            <div className="sidebar-border-line"> </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }

                                {role === 4 || role === 7 ?
                                    <div>
                                        <NavLink to="/external-clinic" exact activeClassName="activeLink">
                                            <ListItem button>
                                                <div className="sidenav-card sidebar-content" >
                                                    <ListItemAvatar>
                                                        <div className="sidebar-icon-outer">
                                                            {currentRoute === 'external-clinic' ?
                                                                <img className="sidebar-image" alt='no_image' src={searchClinic}></img>
                                                                :
                                                                <img className="sidebar-image" alt='no_image' src={searchClinic1}></img>
                                                            }
                                                        </div>
                                                    </ListItemAvatar>
                                                    <Typography className="sidenav-title sidebar-title" variant="subtitle1"> SEARCH CLINICS </Typography>
                                                </div>
                                            </ListItem>
                                        </NavLink>
                                        <div className="sidebar-border">
                                            <div className="sidebar-border-line"> </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }

                                {role === 4 || role === 7 ?
                                    <div>
                                        <NavLink to="/request-list" exact activeClassName="activeLink">
                                            <ListItem button>
                                                <div className="sidenav-card sidebar-content" >
                                                    <ListItemAvatar>
                                                        <div className="sidebar-icon-outer">
                                                            {currentRoute === 'request-list' ?
                                                                <img className="sidebar-image" alt='no_image' src={requestList}></img>
                                                                :
                                                                <img className="sidebar-image" alt='no_image' src={requestList1}></img>
                                                            }
                                                        </div>
                                                    </ListItemAvatar>
                                                    <Typography className="sidenav-title sidebar-title" variant="subtitle1"> REQUEST LIST </Typography>
                                                </div>
                                            </ListItem>
                                        </NavLink>
                                        <div className="sidebar-border">
                                            <div className="sidebar-border-line"> </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }

                                <NavLink to="/call-history" exact activeClassName="activeLink">
                                    <ListItem button>
                                        <div className="sidenav-card sidebar-content" >
                                            <ListItemAvatar>
                                                <div className="sidebar-icon-outer">
                                                    {currentRoute === 'call-history' ?
                                                        <img className="sidebar-image" alt='no_image' src={callHistory}></img>
                                                        :
                                                        <img className="sidebar-image" alt='no_image' src={callHistory1}></img>
                                                    }
                                                </div>
                                            </ListItemAvatar>
                                            <Typography className="sidenav-title sidebar-title" variant="subtitle1"> CALL HISTORY </Typography>
                                        </div>
                                    </ListItem>
                                </NavLink>
                                <div className="sidebar-border">
                                    <div className="sidebar-border-line"> </div>
                                </div>

                                <NavLink id="my-account" to="/my-account" exact activeClassName="activeLink">
                                    <ListItem button>
                                        <div className="sidenav-card sidebar-content" >
                                            <ListItemAvatar>
                                                <div className="sidebar-icon-outer">
                                                    {currentRoute === 'my-account' || currentRoute === 'change-password' ?
                                                        <img className="sidebar-image" alt='no_image' src={myAccount}></img>
                                                        :
                                                        <img className="sidebar-image" alt='no_image' src={myAccount1}></img>
                                                    }
                                                </div>
                                            </ListItemAvatar>
                                            <Typography className="sidenav-title sidebar-title" variant="subtitle1"> MY ACCOUNT </Typography>
                                        </div>
                                    </ListItem>
                                </NavLink>
                                <div className="sidebar-border">
                                    <div className="sidebar-border-line"> </div>
                                </div>

                            </div>
                        </List>
                    </div>
                </div>
            </div >
        )
    }
}
