import React from 'react';
import '../static/styles/App.css'
import Routes from '../routes/router'

function App() {
  return (
    <div>
        <Routes />
    </div>
  );
}

export default App;
