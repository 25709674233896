import React from 'react';
import '../../static/styles/call-waiting-popup.css';
import avatar from '../../static/assets/avatar.png';
import { connect } from "react-redux";
import { Button } from '@material-ui/core';
import Environments from '../../services/environments';
import { withRouter } from "react-router";
import StorageConfiguration from '../../services/storage-configuration';
import Loader from 'react-loader-spinner';
import soundfile from '../../static/assets/perinatal-access-tone.mp3';

class CallWaitingPopup extends React.Component {

    componentDidMount() {
        this.playAudio();
    }

    playAudio() {
        let audioElement = document.getElementById('call-audio');
        audioElement.muted = false;
        var playPromise = audioElement.play();
        if (playPromise !== undefined) {
            playPromise
                .then(_ => {
                    // console.log("audio played auto");
                })
                .catch(error => {
                    // console.log("playback prevented");
                });
        }
    }

    componentDidUpdate() {
        if (this.props.callResponseType === 'attended' || this.props.callResponseType === 'declined' || this.props.callResponseType === 'No Response') {
            this.stopAudio();
        }
    }

    stopAudio() {
        let audioElement = document.getElementById('call-audio');
        audioElement.muted = false;
        var playPromise = audioElement.pause();
        if (playPromise !== undefined) {
            playPromise
                .then(_ => {
                    // console.log("audio played auto");
                })
                .catch(error => {
                    // console.log("playback prevented");
                });
        }
    }


    joinRoom() {
        let roomDetails = {
            identity: StorageConfiguration.sessionGetItem('userId'),
            room_name: this.props.roomDetails.room_name
        }
        const getAuthToken = Environments.twilioTokenAPI(roomDetails)
        getAuthToken.then(res => {
            console.log('res', res);
            let roomData = {
                roomName: roomDetails.room_name,
                token: res.data.data,
                identity: roomDetails.identity,
                type: 'caller'
            }
            this.props.closeWaitingPopup();
            this.props.history.push("/video-call", { roomData: roomData });
        }).catch(err => {
            console.log('err', err);
        });
    }

    cancelCall = () => {
        let data = {};
        let recepientsList = this.props.data.recepientsInfo;
        data.recepientsInfo = [...recepientsList];
        data.signalRType = 'CANCEL_CALL';
        this.props.sendMessage(data);
        this.props.closeWaitingPopup();
    }

    render() {
        return (
            <div className='popup'>
                <div className='callconnectingpopupinner'>

                    <div className="modal-content">

                        <audio id="call-audio" loop>
                            <source src={soundfile} type="audio/ogg" />
                            <source src={soundfile} type="audio/mpeg" />
                        </audio>

                        <div className="modal-header">
                            <h4 className="modal-title">Connecting...</h4>
                        </div>
                        {
                            this.props.data.recepientsInfo.map((data, i) => (

                                Number(this.props.data.callerInfo.callerId) !== data.userId ?
                                    <div key={i}>

                                        <div className='call-image-view'>
                                            <img className="call-image-style" src={data.profilePic ? data.profilePic : avatar} alt="Perinatal access logo" />
                                        </div>

                                        <div className='call-name-view'>
                                            <span style={{ fontSize: '12px', color: '#2c5566', fontWeight: 'bold' }}>Call with </span>
                                            <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{data.userName}</span>
                                            <Loader type="ThreeDots" color="#2c5566" height={50} width={50} timeout={80000} />

                                        </div>

                                    </div>
                                    :
                                    ''
                            ))
                        }
                    </div>

                    <div className="modal-footer">
                        {
                            this.props.callResponseType === 'attended' ?
                                <Button variant="outlined" style={{ background: '#2c5566', color: '#FFF', marginBottom: '5px' }} onClick={() => { this.joinRoom(this.props.callerInfo) }}>Join </Button>
                                :
                                this.props.callResponseType === 'declined' ?
                                    <div style={{ textAlign: 'center' }}>
                                        <div style={{ fontSize: '20px', fontWeight: 'bold', padding: '20px' }}>User declined the call</div>
                                        <Button variant="outlined" style={{ background: '#2c5566', color: '#FFF', marginBottom: '5px' }} onClick={this.props.closeWaitingPopup}>Cancel </Button>
                                    </div>
                                    :
                                    this.props.callResponseType === 'No Response' ?
                                        <div style={{ textAlign: 'center' }}>
                                            <div style={{ fontSize: '20px', fontWeight: 'bold', padding: '20px' }}>No Response for the call</div>
                                            <Button variant="outlined" style={{ background: '#2c5566', color: '#FFF', marginBottom: '5px' }} onClick={this.props.closeWaitingPopup}>Cancel </Button>
                                        </div>
                                        :
                                        <div style={{ textAlign: 'center' }}>
                                            <div style={{ fontSize: '20px', }}>Waiting for the response</div>
                                            <Button className="mt-3" variant="outlined" style={{ background: '#2c5566', color: '#FFF', marginBottom: '5px' }} onClick={this.cancelCall}>Cancel Call</Button>
                                        </div>
                        }
                    </div>

                </div>
            </div>

        );
    }
}

const mapDispachToProps = dispatch => {
    return {
        closeWaitingPopup: () => dispatch({ type: "CLOSE_WAITING_POPUP", value: false }),
        sendMessage: (data) => dispatch({ type: "SIGNALR_OUTGOING_CALLS", value: data }),
    };
};

const mapStateToProps = state => {
    return {
        data: state.recepientsInfo,
        callerInfo: state.incomingCallInfo,
        callResponseType: state.callResponseType,
        roomDetails: state.roomDetails
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispachToProps
)(CallWaitingPopup));