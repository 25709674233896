import React, { Component } from 'react'
import ReactPlayer from 'react-player';

export default class Wowza extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {
        console.log(this.props);
        console.log(localStorage.getItem('wowza'));
    }


    render() {

        return (

            <div className='main-view'>

                <ReactPlayer style={{ position: 'fixed' }} width='100%' height='100%' url={localStorage.getItem('wowza')} controls={false} muted loop playing={true} autoPlay />

            </div >
        )
    }
}
