// Rooms
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const RECEIVE_INCOMING_CALL_RESPONSE = 'RECEIVE_INCOMING_CALL_RESPONSE';
export const OPEN_WAITING_POPUP = 'OPEN_WAITING_POPUP';
export const CLOSE_INCOMING_POPUP = 'CLOSE_INCOMING_POPUP';
export const CLOSE_WAITING_POPUP = 'CLOSE_WAITING_POPUP';
export const CLINIC_ID = 'CLINIC_ID';
export const SAVE_RECIPIENTS = 'SAVE_RECIPIENTS';
export const REMOVE_PARTICIPANTS = 'REMOVE_PARTICIPANTS';

export const SIGNALR_OUTGOING_CALLS = 'SIGNALR_OUTGOING_CALLS';
export const SIGNALR_INCOMING_CALL_RESPONSE = 'SIGNALR_INCOMING_CALL_RESPONSE';
export const SIGNALR_NOTIFY_STREAMING_URL = 'SIGNALR_NOTIFY_STREAMING_URL';
export const RECEIVE_STREAMING_URL = 'RECEIVE_STREAMING_URL';
export const RE_ASSIGN_ORGANIZER = 'RE_ASSIGN_ORGANIZER';
export const CHANGE_ROOM_ORGANIZER = 'CHANGE_ROOM_ORGANIZER';
export const USER_STATUS = 'USER_STATUS';
export const RECEIVE_USER_STATUS = 'RECEIVE_USER_STATUS';
export const UPDATE_REFRESH_PHYSICIANS = 'UPDATE_REFRESH_PHYSICIANS';
export const MISSED_CALL_NOTIFICATION = 'MISSED_CALL_NOTIFICATION';
export const RECEIVE_MISSED_CALL_NOTIFICATION = 'RECEIVE_MISSED_CALL_NOTIFICATION';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';